.selections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* height: 50vh; */
    /* gap: 10px; */
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 10%;
  }
  
  /* .button {
    flex-grow: 1;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    color: white;
  }
   */


/* CSS */
.button{
  align-items: center;
  background: #f5f7fc;
  border: 0;
  border-radius: 15px;
  box-shadow: -10px -10px 30px 0 #fff,10px 10px 30px 0 #1d0dca17;
  box-sizing: border-box;
  color: #2a1f62;
  cursor: pointer;
  display: flex;
  font-family: "Cascadia Code",Consolas,Monaco,"Andale Mono","Ubuntu Mono",monospace;
  font-size: 1rem;
  justify-content: center;
  line-height: 1.5rem;
  padding: 4.5%;
  position: relative;
  text-align: left;
  transition: .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
  width: max-content;
  word-break: normal;
  word-spacing: normal;
  margin: 1%;
}

.button:hover {
  background: #f8f8ff;
  box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}

.one:hover {
    background: #fb9cd4;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}
.two:hover {
    background: #ff5373;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}
.three:hover {
    background: #e666ff;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}
.four:hover {
    background: #a564fa;
    box-shadow: -15px -15px 30px 0 #fff, 15px 15px 30px 0 #1d0dca17;
}
  


/* Parent background */
/* #F5F5FA */


/* Quote Screen ------------------ */

.quote-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 203, 231, 0.8);
    transition: background-color 1.5s ease;
    cursor: pointer;
  }
  
  .quote {
    font-size: 2rem;
    color: white;
    text-align: center;
    justify-content: center;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 1%;
    font-weight: 300;
    line-height: 2.6rem;
    padding: 5%;
    margin: 26%;
  }










/* Media Adjustments Keep at bottom */

@media (max-width: 1000px) {
    .quote {
        font-size: 1.6rem;
      }
  }
@media (max-width: 860px) {
    .button {
      padding: 5%;
    }
    .quote {
        font-size: 1.2rem;
      }
  }
  @media (max-width: 800px) {
    .selections {
        margin-top: 15%;
    }
    .button {
      padding: 6%;
      margin: 0.2%;
      line-height: 2rem;
    }
    .quote {
      margin-top: 25%;
      margin-left: 6%;
      margin-right: 6%;
    }
  }
        