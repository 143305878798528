.masonry-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    max-width: 1200px; /* Set a maximum width for the masonry layout */
    margin: 0 auto; /* Center the masonry layout horizontally */
    padding: 0 20px; /* Add padding to create white space on the sides */
    justify-content: center; /* Center items within the grid */
    margin-top: 15%;
  }
  
  .masonry-item {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    font-size: 16px; /* Adjust the font size as needed */
    font-family: Georgia, serif;
    color: #555;
  }
  
  
  .masonry-item:hover {
    transform: scale(1.05);
  }
  
  .masonry-item img {
    max-width: 100%;
    height: auto;
  }
  
  .masonry-item h3 {
    margin: 10px 0;
    font-weight: bold;
  }
  
  .masonry-item p {
    font-size: 14px;
    color: #555;
  }
  