/* ResponsiveVideo.css */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
  
  .video-wrapper {
    position: relative;
    padding-top: 56.25%;
    width: 90%;
    /* max-width: 800px; */
  }
  
  .video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .videoframe {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center;
  }