.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .centered-area {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .radio-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .radio-buttons input[type='radio'] {
    display: none;
  }
  
  .radio-buttons label {
    display: inline-block;
    padding: 8px 16px;
    margin: 0 4px;
    color: #333;
    background-color: #f1f1f1;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .radio-buttons label:hover {
    background-color: #e1e1e1;
  }
  
  .input-area {
    margin-bottom: 20px;
  }
  
  textarea {
    width: 93%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #dddddd;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #ffabd5;
  }
  .radio-buttons label.selected {
    background-color: #ffabd5;
    color: #fff;
  }


  @media (max-width: 800px) {
    .container {
      height: 110vh;
    }
    textarea {
      width: 80%;
    }
  }